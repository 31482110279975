import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation } from "swiper/modules";
import {
  SecondHeading,
  ParentSection,
  ExtraBoldText,
  Testimonial,
} from "../Website-Styled/theme-styled";
import { Grid, Box, Typography, Container } from "@mui/material";
function Portfolio() {
  return (
    <Grid sx={{ textAlign: "center", mb: "60px", justifyContent: "center" }}>
      <SecondHeading>What Our Customer Says</SecondHeading>
      <ExtraBoldText>
        Necessitatibus fuga eius consequatur ex aliquid fuga eum quidem sint
        consectetur eius ex aliquid
      </ExtraBoldText>
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={1}
        breakpoints={{
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
        slideActiveClass="centered"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        modules={[EffectCoverflow, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial sx={{ width: "600px" }}>
            <Typography sx={{ fontSize: "22px", color: "#b8b6bc", mt: "70px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              explicabo repellat doloremque quisquam accusamus, obcaecati ab
              alias vero fuga, impedit laborum consequuntur.
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#b8b6bc",
                mt: "30px",
              }}
            >
              Rick Wright
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#b8b6bc",
                mt: "10px",
              }}
            >
              Executive Engineer
            </Typography>
          </Testimonial>
        </SwiperSlide>

        <Box className="slider-controler">
          <Box className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Box>
          <Box Box className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </Box>
        </Box>
      </Swiper>
    </Grid>
  );
}

export default Portfolio;
