import { Container } from "@mui/material";
import ManImg from "../images/carausel-1.png";
import * as React from "react";
import Grid from "@mui/material/Grid";
import { cardData } from "../WebsiteData/data";
import ServiceCards from "./serviceCards";
import {
  BoldText,
  ExtraBoldText,
  SecondHeading,
  MyButton,
  ParentSection,
} from "../Website-Styled/theme-styled";

export default function About() {
  return (
    <Grid py={"50px"}>

      <Container maxWidth="xl">
      <SecondHeading>Why Adnovatech?</SecondHeading>
      <ExtraBoldText py='20px'>
       Contrary to popular beleif. Voluptatem dignissimos provident quasi corporis provident
      assumenda.
      </ExtraBoldText>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              lg: "row",
            },
          }}
        >
          <Grid item xs={12} lg={6} my={"40px"} pr={"25px"}>
            <img src={ManImg} alt="meeting" />
          </Grid>
          <Grid item xs={12} lg={6} pl={"25px"}>
            <BoldText>
              Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
              irure dolor in reprehenderit in voluptate velit esse cillum dolore
              proident, sunt in culpa qui officia deserunt mollit anim id est
              Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
              irure dolor in Ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in
            </BoldText>

            <BoldText mt={"16px"}pb={"62px"}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              soluta odio exercitationem reprehenderi Ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
            </BoldText>
            <MyButton>Get Started</MyButton>
          </Grid>
        </Grid>
        <ParentSection container spacing={2} xs={12}>
          {cardData.map((curElem) => {
            return (
              <>
                <ServiceCards key={curElem} {...curElem} />
              </>
            );
          })}
        </ParentSection>
      </Container>
    </Grid>
  );
}
