import React from "react";
import Banner from "../Components/banner";
import About from "../Components/about";
import Portfolio from "../Components/portfolio";
import Faq from "../Components/faq";
import OurClient from "../Components/ourClient";
import OurNewsLater from "../Components/ourNewsLater"
import OurTeam from "../Components/ourTeam"
import Contact from "../Components/contact"

export default function home() {
  return (
    <>
      <Banner />
      <OurClient/>
      <About />
       <Faq />
      <Portfolio />
      <OurTeam/>
      <Contact/>

      {/* <OurNewsLater/>  */}
      

    </>
  );
}
