import NorthEastIcon from '@mui/icons-material/NorthEast';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import XIcon from '@mui/icons-material/X';
import client1 from "../images/client-1.png";
import client2 from "../images/client-2.png";
import client3 from "../images/client-3.png";
import client4 from "../images/client-4.png";
import client5 from "../images/client-5.png";
import client6 from "../images/client-6.png";
import client7 from "../images/client-7.png";

export const pages = [
  {path:'/', label: "Home" },
  {path:'/about', label: "About" },
  {path:'/services', label: "Services" },
  {path:'/portfolio', label: "Portfolio" },
  {path:'/team', label: "Team" },
  {path:'/contact', label: "Contact" },
];

export const cardData = [
  {
    id: 1,
    icon: <NorthEastIcon />,
    title: "15+",
    description:
      "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi",
  },
  {
    id: 2,
    icon: <NorthEastIcon />,
    title: "02+",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
  },
  {
    id: 3,
    icon: <NorthEastIcon />,
    title: "20+",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia",
  },
  {
    id: 4,
    icon: <NorthEastIcon />,
    title: "600+",
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis",
  },
];


export const FaqData = [
  {
    id: "01",
    quest: "Web Development",
    ans: "Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  },
  {
    id: "02",
    quest: "App Development",
    ans: "Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  },
  {
    id: "03",
    quest: "Social Media Marketing",
    ans: "Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  },
  {
    id: "04",
    quest: "Pay-Per-Click Advertising ",
    ans: "Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  },
  {
    id: "05",
    quest: "UI UX Designing",
    ans: "Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  },
  {
    id:"06",
    quest: "Software Development",
    ans: "Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  },
];


export const ContactFormData = [
  {
    id: "1",
    icon: <LocationOnOutlinedIcon />,
    title: "Address",
    label: "A108 Adam Street, New York, NY 535022",
  },
  {
    id: "2",
    icon: <LocalPhoneOutlinedIcon />,
    title: "Call Us",
    label: "(123) 456-7890",
  },
  {
    id: "3",
    icon: <MarkunreadOutlinedIcon />,
    title: "Email Us",
    label: "XYZ @Fyxify.com",
  },
];
export const ClientsData = [
  { imgc: client1 },
  { imgc: client2 },
  { imgc: client3 },
  { imgc: client4 },
  { imgc: client5 },
  { imgc: client6 },
  { imgc: client7 },
];

export const TestoniasClientsData = [
 { cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
   uName:"Rick Wright",
   uTitle:"Sales Exicutive",
 },
 { cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
 uName:"Rick Wright",
 uTitle:"Sales Exicutive",
},
{ cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
uName:"Rick Wright",
uTitle:"Sales Exicutive",
},
{ cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
uName:"Rick Wright",
uTitle:"Sales Exicutive",
},
{ cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
uName:"Rick Wright",
uTitle:"Sales Exicutive",
},
{ cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
uName:"Rick Wright",
uTitle:"Sales Exicutive",
},
{ cmnt:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque explicabo repellat doloremque quisquam accusamus, obcaecati ab alias vero fuga, impedit laborum consequuntur.",
uName:"Rick Wright",
uTitle:"Sales Exicutive",
},

]

export const FooterData = [
  { 
  id: "1" ,
  name2:'About',
  name1:'Portfolio',
  name3:'Contact',
  name4:'Services',
  },
  { 
    id: "2" ,
    name1:'Discussion Forums',
    name2:'Business Offer',
    name3:'Expert Mentors',
    name4:'Detailed Syllabus',
    },
    { 
      id: "3" ,
      name1:'Contact',
      name2:'Email Support',
      name3:'Live Chat',
      name4:'Phone Support',
      name5:'Community',

      },
      { 
        id: "4" ,
        name2:'Email',
        email:"adnovatech@gmail.com",
        name3:'Location',
        location:"adnovatech@gmail.com"
  
        },
    { 
      id: "5" ,
      name1:'Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies',
      icon1:<FacebookRoundedIcon/>,
      icon3:<XIcon/>,

      },

];