import React from "react";
import Grid from "@mui/material/Grid";
import { Icon, MyCard, SecondHeading, Row,BoldText} from "../Website-Styled/theme-styled";
import { Box } from "@mui/material";

export default function ServiceCards(curElem) {
  const { icon, title, description } = curElem;

  return (
    <Grid xs={12} sm={6} lg={3}>
      <MyCard className="myCard">
          <Row>
            <SecondHeading>{title}</SecondHeading>
          <Icon>{icon}</Icon>
          </Row>
          <Box>
          <BoldText variant="body2" mb={"20px"}>
            {description}
          </BoldText>
          </Box>
      </MyCard>
    </Grid>
  );
}
