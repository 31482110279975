import { Box, Container, Grid } from "@mui/material";
import React from "react";
import {
  FooterText,
  Webtitle,
} from "../Website-Styled/theme-styled";
import { FooterData } from "../WebsiteData/data";
export default function footer(curElem) {
  return (
    <Container>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { sm: "row", xs: "column" },
          justifyContent: "space-between",
          marginTop: "30px",
          // textAlign: { sm: "left", xs: "center" },
        }}
      >
        <Grid
          sm={12}
          key={curElem}
          sx={{ display: "flex", flex:'wrap' , flexDirection: { sm: "row", xs: "column" } }}
        >
          {FooterData.map((curElem) => {
            return (
              <>
                <Grid xs={12} sx={{ width: { sm: "20%", xs: "100%",} }} >
                  <FooterText>{curElem.name1}</FooterText>
                  <FooterText>{curElem.name2}</FooterText>
                  <FooterText>{curElem.email}</FooterText>
                  <FooterText>{curElem.name3}</FooterText>
                  <FooterText>{curElem.location}</FooterText>
                  <FooterText>{curElem.name4}</FooterText>
                  <Box sx={{ color: "grey" }}>
                    {curElem.icon1} {curElem.icon3}
                  </Box>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
  
    </Container>
  );
}