import OurClientComponent from "./ourClientComponent";
import { ClientsData } from "../WebsiteData/data";
import React from "react";
import { Box } from "@mui/material";

export default function ourClient() {
  return (
    <Box className="slider">
      <Box className="slide-track">
        {ClientsData.map((curElem) => {
          return <OurClientComponent key={curElem} {...curElem} />;
        })}
      </Box>
    </Box>
  );
}
