import { AccordionDetails } from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MyCardBox } from "../Website-Styled/theme-styled";

export default function faqComponent(curElem) {
  const { id, quest, ans } = curElem;
  return (
    <Grid xs={12} md={6}>
      <MyCardBox>
      <Box mb={"20px"} ml={"10px"}>
        {id} <br />
        {quest}<br />
        {ans}
      </Box>
      </MyCardBox>
    </Grid>
  );
}
