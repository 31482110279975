import { Container } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import {
  MyButton,
  MyBox,
  BanContent,
  BanHeading,
  BanDescription,
} from "../Website-Styled/theme-styled";

export default function Banner() {
  return (
    <MyBox>
      <Container maxWidth="xl">
        <Grid container spacing={2} item xs={12}>
          <BanContent item xs={12}>
            <BanHeading>
              Adnovatech The Best Digital Marketing Agency
            </BanHeading>
            <BanDescription>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas
              corporis perspiciatis consequatur deleniti quis hic culpa
              inventore magnam voluptate{" "}
            </BanDescription>
            <br />
            <MyButton>Get Started</MyButton>
          </BanContent>
        </Grid>
      </Container>
    </MyBox>
  );
}
