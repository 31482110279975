import { Slide } from "../Website-Styled/theme-styled";
import React from "react";
export default function ourClientComponent(curElem) {
  const { imgc } = curElem;
  return (
    <Slide>
      <img src={imgc} alt="" />
    </Slide>
  );
}
