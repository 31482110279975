import {
  Button,
  Grid,
  Typography,
  createTheme,
  Box,
  styled,
  TextField,
} from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#eb5d1e11",
      light: "#fff",
      dark: "#eb5d1e",
      ligthText: "fefefe",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const MyBox = styled(Grid)(({ theme }) => ({
  height: "900px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down('sm')]: {
    height: "auto",
  },
}));

export const MyButton = styled(Button)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "700",
  borderRadius: "50px",
  padding: "20px 46px",
  color: theme.palette.primary.light,
  backgroundImage: "linear-gradient(to right, rgb(80, 129, 161), rgb(157, 55, 129));",
  [theme.breakpoints.down('sm')]: {
    padding: "10px 23px",
    fontSize: "16px",
  },
}));

export const PlayButton = styled(Button)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  border: "none",
  padding: "10px 16px",
  color: theme.palette.primary.ligthText,
  [theme.breakpoints.down('sm')]: {
    padding: "5px 8px",
    fontSize: "12px",
  },
}));

export const BanContent = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down('sm')]: {
    textAlign: "center",
  },
}));

export const BanHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.ligthText,
  textAlign: "center",
  fontSize: "92px",
  fontWeight: "700",
  lineHeight: "96px",
  maxWidth: "1150px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "48px",
    lineHeight: "50px",
    maxWidth: "100%",
  },
}));

export const BanDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.ligthText,
  marginTop: "30px",
  fontSize: "20px",
  textAlign: "center",
  maxWidth: "960px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
    marginTop: "15px",
  },
}));

export const Slide = styled(Box)(({ theme }) => ({
  height: "100px",
  width: "200px",
  [theme.breakpoints.down('sm')]: {
    height: "50px",
    width: "100px",
  },
}));

export const Icon = styled(Box)(({ theme }) => ({
  color: "#3bc1cd",
}));

export const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down('sm')]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const MyCardBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#131631",
  color: "#ffff",
}));

export const MyCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#131631",
  padding: "20px",
  border: "1px solid #2b2d43",
  margin: "10px",
  borderRadius: "16px",
  height: "240px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&:hover": {
    backgroundImage: "linear-gradient(to right, rgb(80, 129, 161), rgb(157, 55, 129));",
  },
  [theme.breakpoints.down('sm')]: {
    height: "auto",
    padding: "10px",
  },
}));

export const ParentSection = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  paddingTop: "40px",
  paddingBottom: "40px",
  [theme.breakpoints.down('sm')]: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
}));

export const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.ligthText,
  fontSize: "36px",
  fontWeight: "600",
  lineHeight: "54px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "24px",
    lineHeight: "36px",
  },
}));

export const DescriptionText = styled(HeadingText)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "100",
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
  },
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  fontSize: "18px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
  },
}));

export const FooterText = styled(BoldText)(({ theme }) => ({
  color: "grey",
  fontSize: "14px",
  margin: "10px 0px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
  },
}));

export const ExtraBoldText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  color: "#ffffff",
  [theme.breakpoints.down('sm')]: {
    fontSize: "20px",
  },
}));

export const SecondHeading = styled(Typography)(({ theme }) => ({
  fontSize: "56px",
  fontWeight: "500",
  color: theme.palette.primary.ligthText,
  lineHeight: "70px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
}));

export const ContactIcon = styled(Box)(({ theme }) => ({
  backgroundColor: "#eb6d",
  height: "40px",
  width: "40px",
  borderRadius: "100%",
  [theme.breakpoints.down('sm')]: {
    height: "30px",
    width: "30px",
  },
}));

export const MyIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.dark,
  opacity: "100%",
  marginLeft: "8px",
  marginTop: "8px",
}));

export const MyContactForm = styled(Grid)(({ theme }) => ({
  background: "none",
  border: "1px solid #707182",
  borderRadius: "30px",
  padding: "50px",
  width: "100%",
  [theme.breakpoints.down('sm')]: {
    padding: "20px",
  },
}));

export const ParentButton = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const Webtitle = styled(Typography)(({ theme }) => ({
  mr: 2,
  fontFamily: "monospace",
  fontWeight: 700,
  color: theme.palette.primary.dark,
  textDecoration: "none",
  [theme.breakpoints.down('sm')]: {
    fontSize: "24px",
  },
}));

export const Testimonial = styled(Box)(({ theme }) => ({
  backgroundImage: "linear-gradient(to right, rgb(37, 33, 62), rgb(42, 27, 61));",
  padding: "40px",
  borderRadius: "10px",
  border: "1px solid #493d59",
  [theme.breakpoints.down('sm')]: {
    padding: "20px",
  },
}));

export const Comment = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: "#b8b6bc",
  marginTop: "70px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "18px",
    marginTop: "30px",
  },
}));

export const UserName = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: "600",
  color: "#b8b6bc",
  marginTop: "30px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "24px",
    marginTop: "15px",
  },
}));

export const UserTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "400",
  color: "#b8b6bc",
  mt: "10px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#151833",
  borderRadius: "50px",
  width: "695px",
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8, 
    '& fieldset': {
      borderColor: '#707182', 
      borderRadius: "50px",
    },
  },
  '& .MuiInputBase-input': {
    color: '#707182', 
    '&::placeholder': {
      color: '#707182',
      opacity: 1,
    },
  },
}));

export const Icons = styled(Box)(({ theme }) => ({
  color: "white",
  ":hover": {
    ".icons": {
      color: theme.palette.primary.dark,
      transform: "scale(1.2)",
    },
  },
}));

export const Protfolio = styled(Box)(({ theme }) => ({
  margin: "20px",
  position: "relative",
  transition: "0.5s all",
  ":hover": {
    ".portfolioImg img": {
      transform: "scale(1.2)",
    },
    ".portOverlay": {
      background: "rgb(0 0 0 / 62%)",
      opacity: "1",
    },
  },
  ".portfolioImg": {
    overflow: "hidden",
    img: {
      transition: "0.5s all",
    },
  },
  ".portOverlay": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    justifyContent: "space-between",
    height: "100%",
    padding: "1rem",
    transition: "0.5s all",
    opacity: "0",
    ".label": {
      color: theme.palette.primary.dark,
    },
    ".description": {
      color: "#fff",
    },
  },
}));

