import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import FaqComponent from "./faqComponent";
import { FaqData } from "../WebsiteData/data";
import {
  DescriptionText,
  HeadingText,
  MyBox,
  ParentSection,
  SecondHeading,
  ExtraBoldText,
} from "../Website-Styled/theme-styled";
export default function faq() {
  return (
    <MyBox>
      <Container maxWidth="lg">
        <ParentSection container spacing={2} item xs={12}>
          <Grid item xs={12} sx={{ textAlign: "center", mb: "60px" }}>
            <SecondHeading>Grown Youre Busines Digitally Ith Us</SecondHeading>
            <ExtraBoldText>
              Necessitatibus fuga eius consequatur ex aliquid fuga eum quidem sint
              consectetur eius ex aliquid
            </ExtraBoldText>
          </Grid>

          {FaqData.map((curElem) => {
            return (
              <>
                <FaqComponent key={curElem} {...curElem} />
              </>
            );
          })}
        </ParentSection>
      </Container>
    </MyBox>
  );
}
