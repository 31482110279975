import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation } from "swiper/modules";
import {SecondHeading,ParentSection} from "../Website-Styled/theme-styled"
import slide_image_1 from "../images/carausel-1.png";
import slide_image_2 from "../images/carausel-2.png";
import slide_image_3 from "../images/carausel-3.png";
import slide_image_4 from "../images/carausel-1.png";
import slide_image_5 from "../images/carausel-2.png";
import slide_image_6 from "../images/carausel-3.png";
import slide_image_7 from "../images/carausel-1.png";
import { Grid , Box} from "@mui/material";
function Portfolio() {
  return (
    <ParentSection>
    <Grid sx={{ textAlign: "center", mb: "60px",justifyContent:"center" }} >
            <SecondHeading sx={{width:"700px",display:"flex", mx:"auto", mb:"40px"}}>In-Depth Case Studies In Software Development</SecondHeading>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={1}
        breakpoints={{
          1200: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

        }}

        coverflowEffect={{
          rotate: 14,
          stretch: 30,
          depth: 100,
          modifier: 2.5,}}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,}}
        modules={[EffectCoverflow, Navigation]}
        className="swiper_container">
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt="slide_image" />
        </SwiperSlide>
        <Box className="slider-controler">
          <Box className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Box>
          <Box Box className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </Box>
        </Box>
      </Swiper>
    </Grid>
    </ParentSection>
  );
}

export default Portfolio;
