import { Box, Container, Grid, TextField, InputLabel } from "@mui/material";
import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import {
  ParentSection,
  DescriptionText,
  HeadingText,
  BoldText,
  SecondHeading,
  ContactIcon,
  ContactLeft,
  MyContactForm,
  MyIcon,
  MyButton,
  ParentButton,
  CustomTextField
} 
from "../Website-Styled/theme-styled";
import { ContactFormData } from "../WebsiteData/data";

export default function Contact() {
const[fullName,setFullName]=useState('');
const[email,setEmail]=useState('');
const[subject,setSubject]=useState('');
const[message,setMessage]=useState('');

const handleSubmit=async(e)=>{

  e.preventDefault();
  const serviceId ='service_5m73wd6';
  const templateId ='template_ckbzxa5';
  const publicKey ='oN6-td0is653xgA3N';
  

  const templateParams={
    from_name:fullName,
    from_email:email,
    subject:subject,
    to_name:'Hassan',
    message:message,
   }
   emailjs.send(serviceId,templateId ,templateParams,publicKey)
   .then((response)=>{
    console.log('Email Sent Succesfully',response);
    setFullName('');
    setEmail('');
    setMessage('');
   })
   .catch((error)=>{
    console.log("Error Sending Message",error)
   })
  }

  return (
    <Grid mt={"16px"}>
      <Container maxWidth="xl">
        <ParentSection container spacing={2} item xs={12}>

          <Grid xs={12} gap={"20px"} display={{ md: "flex" }}>
            <MyContactForm xs={12} >
            <Grid item xs={12} sx={{ textAlign: "center", my:"60px" }}>
          <SecondHeading>Get Start Your Project With Us?</SecondHeading>
          </Grid>
              <form onSubmit={handleSubmit}>
                <Box display={"flex"} gap={"20px"} mb={"40px"}>
                  <Box sx={{ width: "100%" }}>
                    <CustomTextField placeholder="First Name"/>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <CustomTextField placeholder="Last Name"/>
                  </Box>
                </Box>
                <Box display={"flex"} gap={"20px"}>
                  <Box sx={{ width: "100%" }}>
                    <CustomTextField placeholder="Email" />
                  </Box>
                  <Box sx={{ width: "100%" }} >
                    <CustomTextField placeholder="Phone"/>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <textarea  placeholder="Message"
                    rows="8"
                    style={{
                      marginTop:"40px",
                      border: "solid 1px grey",
                      width: "100%",
                      padding:"20px",
                      borderRadius: "20px",
                      background:"none",
                      

                    }}
                  ></textarea>
                </Box>

                <ParentButton mt={"30px"}>
                  <MyButton type="submit">Send Message</MyButton>
                </ParentButton>
              </form>
            </MyContactForm>
          </Grid>
        </ParentSection>
      </Container>
    </Grid>
  );
}
