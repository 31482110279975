import "./App.css";
import Navbar from "./Components/navbar";
import Home from "./Pages/home"
import Footer from "./Components/footer"

function App() {
  return (
    <>
    <Navbar/>
    <Home/>
    <Footer/>
    </>
  );
}

export default App;
